import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { TemplateSelectorComponent } from './components/template-selector/template-selector.component';
import {HttpClientModule} from '@angular/common/http';
import {MatButtonModule, MatCardModule, MatCheckboxModule, MatInputModule} from '@angular/material';
import {MatRadioModule} from '@angular/material/radio';
import {MatIconModule} from '@angular/material/icon';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TemplatePreviewComponent } from './components/template-preview/template-preview.component';
import { TemplatesListComponent } from './components/templates-list/templates-list.component';
import { TemplateItemComponent } from './components/template-item/template-item.component';
import { BilldataInputComponent } from './components/billdata-input/billdata-input.component';
import { from } from 'rxjs';

@NgModule({
  declarations: [
    AppComponent,
    TemplateSelectorComponent,
    DynamicFormComponent,
    TemplatePreviewComponent,
    TemplatesListComponent,
    TemplateItemComponent,
    BilldataInputComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    MatRadioModule,
    MatIconModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
