import {HttpClient, HttpHeaders} from '@angular/common/http';
import { take } from 'rxjs/operators';
import { from } from 'rxjs';
import QRCode from 'qrcode';

export class QRCodeService {
    private baseUrl: string;
    private baseHost: string;

    constructor(private http: HttpClient) {
        this.baseUrl = 'http://qrcoder.ru/';
        this.baseHost = 'qrcoder.ru';
    }

    public async getCodeImageUrl(data: string) : Promise<string> {
        const html = await this.http.post(this.baseUrl, {
            d: data, 
            s: 4,
        }, {
            responseType: 'text',
            headers: new HttpHeaders({
                Accept:'text/html',
                'Content-Type': 'application/x-www-form-urlencoded',
                Host: this.baseHost,
                Origin: 'http://' + this.baseHost
            })
        }).pipe(take(1)).toPromise();
        const htmlObject = document.createElement('div');
        htmlObject.style.display = 'none';
        htmlObject.innerHTML = html;
        const imageContainer = htmlObject.querySelector('.image');
        if (imageContainer) {
            const image = imageContainer.querySelector('img');
            if (image) {
                return image.getAttribute('src');
            }
        }
        return '';
    }

    public renderCodeOnCanvas(data: string, canvas: HTMLCanvasElement) {
        QRCode.toCanvas(canvas, data, {width: canvas.getAttribute('width'), height: canvas.getAttribute('height')},function (error) {
            if (error) console.error(error)
            console.log('success!');
        })
    }
}