import JsBarcode from 'jsbarcode';

export class BarcodeService {
    constructor () {}

    public setBarcode(canvas: HTMLCanvasElement, data: string) {
        JsBarcode(canvas, data, {
            font: canvas.getAttribute('font') || 'Times New Roman',
            width: parseFloat(canvas.getAttribute('bar-width')) || 2,
            height: parseFloat(canvas.getAttribute('initial-height')) || 2,
            fontSize: parseFloat(canvas.getAttribute('font-size')) || 16,
        });
    }
}