import {Component, Input, OnInit, Renderer2, ViewChild, ElementRef} from '@angular/core';
import {WordTemplate, HtmlService} from '../../services/html.service';

@Component({
  selector: 'app-templates-list',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.scss']
})
export class TemplatesListComponent implements OnInit {
  @ViewChild('container') container: ElementRef<HTMLDivElement>;
  @Input() templates: WordTemplate[] = [];
  @Input() selectedTemplateIndex = 0;
  constructor(private templatesService: HtmlService) {
    this.templatesService.selectedTemplateId$.subscribe(id => {
      this.selectedTemplateIndex = this.templates.indexOf(this.templatesService.templates[id]);
    });
  }

  ngOnInit() {
  }

  async onTemplateSelected(index: number): Promise<void> {
    await this.templatesService.selectTemplate(this.templates[index]);
    this.templatesService.randomizeTags();
  }
  async selectPrevious() {
    if (this.selectedTemplateIndex > 0) {
      await this.templatesService.selectTemplate(this.templates[--this.selectedTemplateIndex]);
      this.templatesService.randomizeTags();
      this.container.nativeElement.scroll(this.selectedTemplateIndex*86, 0);
    }
  }
  async selectNext() {
    if (this.selectedTemplateIndex < this.templates.length-1) {
      await this.templatesService.selectTemplate(this.templates[++this.selectedTemplateIndex]);
      this.templatesService.randomizeTags();
      this.container.nativeElement.scroll(this.selectedTemplateIndex*86, 0);
    }
  }
}
