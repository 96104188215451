import {Component, EventEmitter, Input, Output} from '@angular/core';
import {WordField} from '../../services/html.service';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent {
  @Output() dataChange: EventEmitter<{[key: string]: any}> = new EventEmitter();
  @Input() set fields(f: WordField[]) {
    this._fields = f;
    this.generateForm(f);
  };
  get fields(): WordField[] {
    return this._fields;
  }
  private _fields: WordField[];
  form: FormGroup;
  constructor(private fb: FormBuilder) {}

  private generateForm(fields: WordField[]): void {
    this.form = this.fb.group(fields.reduce((formObj, field) => {
      return {...formObj, [field.name]: null};
    }, {}));

    this.form.valueChanges.subscribe((val) => {
      this.dataChange.emit(val);
    });
  }

}
