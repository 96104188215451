import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, take} from 'rxjs/operators';
import {BehaviorSubject, Observable, from} from 'rxjs';
import { template } from '@angular/core/src/render3';
import number_to_stringEn from './number2texten.js'
import number_to_stringRu from './number2textru.js'
import { validateHorizontalPosition } from '@angular/cdk/overlay';
import { getNumberOfCurrencyDigits } from '@angular/common';
import {QRCodeService} from './qrcode.service';
import {BarcodeService} from './barcode.service';
import { BillDataService } from './billdata.service.js';
export type TagType = 's' | 'n' | 'b' | 'd' | 'e';
export enum TemplateType {
  WORD = 'word',
  EXCEL = 'excel',
  SCAN = 'scan'
} 
export type RuleType = '<' | '<=' | '=' | '>=' | '>' | '+' | 'exp';
export type MonthFormat = 'text' | 'number' | 'togetherEn' | 'togetherEnShort' 
  | 'short' | 'slashes' | 'none' | 'spaces' | 'name' | 'together' | 'nameEn';
export enum FieldType {
  PHONE = 'phone',
  SHORT_TEXT = 'short-text',
  LONG_TEXT = 'long-text',
  NUMERIC = 'numeric',
  DATE = 'date'
}

export interface WordField {
  type: FieldType;
  name: string;
  title?: string;
  bold?: boolean;
  italic?: boolean;
  width?: number;
  formatFunction?: (val : any) => string;
}

export interface WordTemplate {
  name: string;
  url: string;
  casesUrl?: string;
  fields: WordField[];
  maxElementsDeteled?: number;
  generateQR?: boolean;
  generateBarcode?: boolean;
  billData?: boolean;
  type: TemplateType
}

export interface RandomizeRule {
  linkedTagsIds?: string[];
  type?: RuleType;
}


export interface ValueTemplate {
  id?: string;
  elem: HTMLElement;
  rules?: RandomizeRule[];
  handled?: boolean;
}

export interface DateTemplate extends ValueTemplate {
  value: Date;
  template?: (val: Date) => string;
  difference: number;
}

export interface MonthTemplate extends ValueTemplate {
  value: Date;
  template?: (val: Date) => string;
  difference: number;
}

export interface TimeTemplate extends ValueTemplate {
  value: Date;
  timeRange: number,
  template?: (val: Date) => string;
}

export interface AccountTemplate extends ValueTemplate {
  value: string;
  template?: (val: string) => string;
}

export interface NumericTemplate extends ValueTemplate {
  value: number;
  template?: (val: number) => string;
  spreadPercent?:number;
  fractionalDigits?:number;
  minValue?: number;
  maxValue?: number;
  expression?: string;
}

export interface CurrencyTemplate extends ValueTemplate {
  value: number;
  template?: (val: number) => string;
  minValue?: number;
  maxValue?: number;
  spreadPercent?:number;
  expression?: string;
}

export interface DateRangeTemplate extends ValueTemplate {
  value: Date;
  template?: (val: Date) => string;
  minDifference: number;
  maxDifference: number;
}

export interface CasesTemplate extends ValueTemplate {
  value: string;
  template?: (val: string) => string;
  cases: string[];
}

export interface StampTemplate extends ValueTemplate {
  initialX: number;
  initialY: number;
  xRange: number;
  yRange: number;
  angleRange: number;
  xPositionAttribute: string;
  yPositionAttribute: string;
}

const templates: WordTemplate[] = [
  {
    name: 'Серпухов',
    type: TemplateType.EXCEL,
    url: '/assets/Serpuhov/index.html',
    generateBarcode: true,
    billData: true,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Москва',
    type: TemplateType.EXCEL,
    url: '/assets/Moskva/1.html',
    generateQR: true,
    billData: true,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'measureDate',
        title: 'Дата последнего платежа',
        type: FieldType.DATE
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Хабаровск',
    type: TemplateType.EXCEL,
    url: '/assets/Habarovsk/1.html',
    generateQR: true,
    billData: true,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Самара',
    type: TemplateType.EXCEL,
    url: '/assets/Samara/1.html',
    generateQR: true,
    billData: true,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Омск',
    type: TemplateType.EXCEL,
    url: '/assets/Kvitantsia_Omsk/1.html',
    billData: true,
    generateBarcode: true,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Тинькофф без таблицы',
    url:'/assets/rutinkov/en15062018.html',
    casesUrl: '/assets/rutinkov/cases.json',
    type: TemplateType.WORD,
    fields: [
      {
        name: 'fi',
        title: 'Фамилия Имя',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'second_name',
        title: 'Отчество',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'address1',
        title: 'Верхняя часть адреса',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address2',
        title: 'Нижняя часть адреса',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address3',
        title: 'Индекс',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'cardNumber',
        title: 'Номер карты',
        type: FieldType.SHORT_TEXT,
        formatFunction: (val: string) => {
          let mask='';
          let digitsCount = val.length < 16 ? 9 : 10;
          for (let i =0;i<Math.min(19,val.length)-digitsCount;i++) {
            mask += '*';
          }
          const endingDigits = val.length < 16 ? 3 : 4;
          const secondPart = mask + val.substr(Math.min(19,Math.max(val.length,13))-endingDigits, endingDigits)
          return val.substring(0,6) + (val.length < 13 ? '' : secondPart);
        },
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Тинькофф с таблицей',
    url: '/assets/tinkoff/index.html',
    casesUrl: '/assets/tinkoff/cases.json',
    type: TemplateType.WORD,
    fields: [
      {
        name: 'fio',
        title: 'Фамилия Имя Отчество',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'street',
        title: 'Улица',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'house',
        title: 'Дом, квартира',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'city',
        title: 'Город',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'state',
        title: 'Область',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'index',
        title: 'Индекс',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'cardNumber',
        title: 'Номер карты',
        type: FieldType.SHORT_TEXT,
        formatFunction: (val: string) => {
          let mask='';
          let digitsCount = val.length < 16 ? 9 : 10;
          for (let i =0;i<Math.min(19,val.length)-digitsCount;i++) {
            mask += '*';
          }
          const endingDigits = val.length < 16 ? 3 : 4;
          const secondPart = mask + val.substr(Math.min(19,Math.max(val.length,13))-endingDigits, endingDigits)
          return val.substring(0,6) + (val.length < 13 ? '' : secondPart);
        },
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Сбербанк',
    url: '/assets/sber/index5.html',
    casesUrl: '/assets/sber/cases.json',
    type: TemplateType.WORD,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'add1',
        title: 'Верхняя часть адреса',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'add2',
        title: 'Нижняя часть адреса',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'index',
        title: 'Индекс',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'cardNumber',
        title: 'Номер карты',
        type: FieldType.SHORT_TEXT,
        formatFunction: (val: string) => val.substring(0,4) + ' **** **** ' + val.substring(12, 16)
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Тинькофф 3 англ',
    url: '/assets/tinkoff3/index.html',
    casesUrl: '/assets/tinkoff3/cases.json',
    type: TemplateType.WORD,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Адрес',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'cardNumber',
        title: 'Номер карты',
        type: FieldType.SHORT_TEXT,
        formatFunction: (val: string) => {
          let mask='';
          let digitsCount = val.length < 16 ? 9 : 10;
          for (let i =0;i<Math.min(19,val.length)-digitsCount;i++) {
            mask += '*';
          }
          const endingDigits = val.length < 16 ? 3 : 4;
          const secondPart = mask + val.substr(Math.min(19,Math.max(val.length,13))-endingDigits, endingDigits)
          return val.substring(0,6) + (val.length < 13 ? '' : secondPart);
        },
      },
      {
        name: 'account',
        title: 'Номер счета',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      },
      {
        name: 'cardIssueDate',
        title: 'Дата выпуска карты',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Тинькофф 3 русский',
    url: '/assets/tinkoff3ru/index.html',
    casesUrl: '/assets/tinkoff3ru/cases.json',
    maxElementsDeteled: 16,
    type: TemplateType.WORD,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Дом, квартира',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'street',
        title: 'Улица',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'city',
        title: 'Город',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'index',
        title: 'Индекс',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'cardNumber',
        title: 'Номер карты',
        type: FieldType.SHORT_TEXT,
        formatFunction: (val: string) => {
          let mask='';
          let digitsCount = val.length < 16 ? 9 : 10;
          for (let i =0;i<Math.min(19,val.length)-digitsCount;i++) {
            mask += '*';
          }
          const endingDigits = val.length < 16 ? 3 : 4;
          const secondPart = mask + val.substr(Math.min(19,Math.max(val.length,13))-endingDigits, endingDigits)
          return val.substring(0,6) + (val.length < 13 ? '' : secondPart);
        },
      },
      {
        name: 'account',
        title: 'Номер счета',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      },
      {
        name: 'cardIssueDate',
        title: 'Дата выпуска карты',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Сбербанк скан 1',
    url: '/assets/sberscan1/index.html',
    type: TemplateType.WORD,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Адрес',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'account',
        title: 'Номер счета',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      },
      {
        name: 'passport',
        title: 'Номер паспорта',
        type: FieldType.SHORT_TEXT,
        formatFunction: (val: string) => val.substring(0, 2)+' '+val.substring(2,4)+' '+val.substring(4,10)
      },
      {
        name: 'passport-issued',
        title: 'Паспорт выдан',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'passport-issue-date',
        title: 'Дата выдачи паспорта',
        type: FieldType.DATE
      },
      {
        name: 'accountIssueDate',
        title: 'Дата создания счета',
        type: FieldType.DATE
      },
    ]
  },
  {
    name: 'Сбербанк скан 2',
    type: TemplateType.WORD,
    url: '/assets/sberscan2/index.html',
    casesUrl: '/assets/sberscan2/cases.json',
    maxElementsDeteled: 9,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'fio-en',
        title: 'ФИО англ',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      },
      {
        name: 'cardNumber',
        title: 'Номер карты',
        type: FieldType.SHORT_TEXT,
        formatFunction: (val: string) => val.substr(11, 1) + ' ' + val.substr(12,4)+ ' ' + val.substr(16,4)
      }
    ]
  },
  {
    name: 'Юникредит скан 1',
    type: TemplateType.WORD,
    url: '/assets/unicredit/index.html',
    casesUrl: '/assets/unicredit/cases.json',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'fio-en',
        title: 'ФИО англ',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'address',
        title: 'Адрес',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'address-en',
        title: 'Адрес англ',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'branch',
        title: 'Отделение',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'account',
        title: 'Номер счета',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Открытие скан 1',
    type: TemplateType.WORD,
    url: '/assets/Otkrytie1/index.html',
    casesUrl: '/assets/Otkrytie1/cases.json',
    maxElementsDeteled: 4,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'address',
        title: 'Адрес',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'cardNumber',
        title: 'Номер карты',
        type: FieldType.SHORT_TEXT,
        formatFunction: (val: string) => {
          let mask='';
          let digitsCount = val.length < 16 ? 9 : 10;
          for (let i =0;i<Math.min(19,val.length)-digitsCount;i++) {
            mask += '*';
          }
          const endingDigits = val.length < 16 ? 3 : 4;
          const secondPart = mask + val.substr(Math.min(19,Math.min(19,Math.max(val.length,13)))-endingDigits, endingDigits)
          return val.substring(0,6) + (val.length < 13 ? '' : secondPart);
        }
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Райффайзен скан 1',
    type: TemplateType.WORD,
    url: '/assets/Raiffaizen/index.html',
    fields: [
      {
        name: 'fio',
        title: 'Фамилия Имя Отчество',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'fio-short',
        title: 'Фамилия И.О.',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'address',
        title: 'Адрес',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'cardNumber',
        title: 'Номер карты',
        type: FieldType.SHORT_TEXT,
        formatFunction: (val: string) => val.substring(Math.max(val.length - 4, 0), val.length)
      },
      {
        name: 'currency',
        title: 'Валюта',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'account',
        title: 'Номер счета',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Русский Стандарт скан 1',
    type: TemplateType.WORD,
    url: '/assets/RusskiyStandart1/index.html',
    fields: [
      {
        name: 'last-name',
        title: 'Фамилия',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'io',
        title: 'Имя Отчество',
        type: FieldType.SHORT_TEXT,
        formatFunction: function(val: string) : string{
          const isMale : boolean = val.substring(val.length - 2, val.length).toLowerCase() === 'ич';
          this.currentDocument.querySelectorAll(`[name="ablativeGender"]`).forEach((elem : Element) => {
            elem.innerHTML = isMale ? 'ым' : 'ой';
          });
          this.currentDocument.querySelectorAll(`[name="gender"]`).forEach((elem : Element) => {
            elem.innerHTML = isMale ? 'ый' : 'ая';
          });
          return val;
        }
      },
      {
        name: 'fio-genitive',
        title: 'Фамилия Имя Отчество в творительном падеже',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'passport-start',
        title: 'Серия паспорта',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'passport-end',
        title: 'Номер паспорта',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'passport-issuer',
        title: 'Паспорт выдан',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'passport-issue-date',
        title: 'Дата выдачи паспорта',
        type: FieldType.DATE,
        formatFunction: function(val: string) : string {
          return this.dateFormatFunction(new Date(val), 'text')
        } 
      },
      {
        name: 'address',
        title: 'Адрес',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'contract-date',
        title: 'Дата открытия счета',
        type: FieldType.DATE
      },
      {
        name: 'account',
        title: 'Номер счета',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Открытие скан 2',
    type: TemplateType.WORD,
    url: '/assets/Otkrytie2/index.html',
    casesUrl: '/assets/Otkrytie2/cases.json',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'address',
        title: 'Адрес',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'cardNumber',
        title: 'Номер карты',
        type: FieldType.SHORT_TEXT,
        formatFunction: (val: string) => val.substr(0,6)+'******'+val.substr(12,7)
      },
      {
        name: 'passport-number',
        title: 'Номер паспорта',
        type: FieldType.SHORT_TEXT,
        formatFunction: (val: string) => val.substr(0,2)+' '+val.substr(2,2)+' № '+val.substr(4,6)
      },
      {
        name: 'passport-issue-date',
        title: 'Дата выдачи паспорта',
        type: FieldType.DATE,
        formatFunction: function(val: string) : string {
          return this.dateFormatFunction(new Date(val), 'slashes')
        }
      },
      {
        name: 'passport-issuer',
        title: 'Паспорт выдан',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'account-open-date',
        title: 'Дата счета',
        type: FieldType.DATE,
        formatFunction: function(val: string) : string {
          return this.dateFormatFunction(new Date(val), 'slashes')
        }
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Райффайзен скан 2',
    type: TemplateType.WORD,
    url: '/assets/Raiffaizen2/index.html',
    fields: [
      {
        name: 'fio',
        title: 'Фамилия Имя Отчество',
        type: FieldType.SHORT_TEXT,
        formatFunction: function(val: string) : string{
          const isMale : boolean = val.substring(val.length - 2, val.length).toLowerCase() === 'ич';
          this.currentDocument.querySelectorAll(`[name="gender"]`).forEach((elem : Element) => {
            elem.innerHTML = isMale ? 'на' : 'жи';
          });
          return val;
        }
      },
      {
        name: 'address',
        title: 'Адрес',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Уралсиб скан 1',
    type: TemplateType.WORD,
    url: '/assets/Uralsib/index.html',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'address',
        title: 'Адрес',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'passport-start',
        title: 'Серия паспорта',
        type: FieldType.SHORT_TEXT,
        formatFunction: (val: string) => val.substr(0,2) + ' ' + val.substr(2,2)
      },
      {
        name: 'passport-end',
        title: 'Номер паспорта',
        type: FieldType.SHORT_TEXT,
        formatFunction: (val: string) => val.substr(0,6)
      },
      {
        name: 'passport-issue-date',
        title: 'Дата выдачи паспорта',
        type: FieldType.DATE,
      },
      {
        name: 'passport-issuer',
        title: 'Паспорт выдан',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'birth-date',
        title: 'Дата рождения',
        type: FieldType.DATE
      },
      {
        name: 'account',
        title: 'Номер счета',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Уралсиб скан 2',
    type: TemplateType.WORD,
    url: '/assets/Uralsib2/index.html',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'address',
        title: 'Адрес',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'passport-number',
        title: 'Номер паспорта',
        type: FieldType.SHORT_TEXT,
        formatFunction: (val: string) => val.substr(0,2) + ' ' + val.substr(2,2) + ' ' + val.substr(4,6)
      },
      {
        name: 'passport-issue-date',
        title: 'Дата выдачи паспорта',
        type: FieldType.DATE,
      },
      {
        name: 'passport-issuer',
        title: 'Паспорт выдан',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'birth-date',
        title: 'Дата рождения',
        type: FieldType.DATE
      },
      {
        name: 'account',
        title: 'Номер счета',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Альфа скан 1',
    type: TemplateType.WORD,
    url: '/assets/Alpha/index.html',
    casesUrl: '/assets/Alpha/cases.json',
    maxElementsDeteled: 7,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'address',
        title: 'Адрес',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'account',
        title: 'Номер счета',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Табличная выписка',
    type: TemplateType.WORD,
    url: '/assets/Vypiska/index.html',
    maxElementsDeteled: 4,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'fio2',
        title: 'Фамилия И.О. в родительном падеже',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'account',
        title: 'Номер счета',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Тинькофф предложение',
    url: '/assets/tinkoff3pre/index.html',
    casesUrl: '/assets/tinkoff3pre/cases.json',
    type: TemplateType.WORD,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'fio-lat',
        title: 'ФИО латиницей',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'address',
        title: 'Адрес',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'cardNumber',
        title: 'Номер карты',
        type: FieldType.SHORT_TEXT,
        formatFunction: (val: string) => {
          let mask='';
          let digitsCount = val.length < 16 ? 9 : 10;
          for (let i =0;i<Math.min(19,val.length)-digitsCount;i++) {
            mask += '*';
          }
          const endingDigits = val.length < 16 ? 3 : 4;
          const secondPart = mask + val.substr(Math.min(19,Math.max(val.length,13))-endingDigits, endingDigits)
          return val.substring(0,6) + (val.length < 13 ? '' : secondPart);
        },
      },
      {
        name: 'client-account',
        title: 'Номер счета',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      },
    ]
  },
  {
    name: 'Почтабанк',
    url: '/assets/pochtabank/index.html',
    type: TemplateType.WORD,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'passport-number',
        title: 'Номер паспорта',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'passport-issue-date',
        title: 'Дата выдачи паспорта',
        type: FieldType.DATE
      },
      {
        name: 'passport-issuer',
        title: 'Паспорт выдан',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'address',
        title: 'Адрес',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'client-account',
        title: 'Номер счета',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Белгород',
    type: TemplateType.EXCEL,
    url: '/assets/Belgorod/1.html',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'address',
        title: 'Адрес',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'measureDate',
        title: 'Дата последнего платежа',
        type: FieldType.DATE
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Лыткарино',
    type: TemplateType.EXCEL,
    url: '/assets/Lytkarino/1.html',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Дом, квартира',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Саратов',
    type: TemplateType.EXCEL,
    url: '/assets/Saratov/1.html',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Дом, квартира',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Орел',
    type: TemplateType.EXCEL,
    url: '/assets/Orel/1.html',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Дом, квартира',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Челябинск',
    type: TemplateType.EXCEL,
    url: '/assets/Chelyabinsk/1.html',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Дом, квартира',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Тюмень',
    type: TemplateType.EXCEL,
    url: '/assets/Tumen/1.html',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Дом, квартира',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Москва 2',
    type: TemplateType.EXCEL,
    url: '/assets/Moskva2/1.html',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Дом, квартира',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Санкт-Петербург',
    type: TemplateType.EXCEL,
    url: '/assets/Saint-Petersburg/1.html',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Дом, квартира',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Тюмень Correct',
    type: TemplateType.EXCEL,
    url: '/assets/TumenCorrect/1.html',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Дом, квартира',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Хабаровск 2',
    type: TemplateType.EXCEL,
    url: '/assets/Habarovsk2/1.html',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Дом, квартира',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Ясногорск',
    type: TemplateType.EXCEL,
    url: '/assets/Yasnogorsk/1.html',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Дом, квартира',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Краснодар',
    type: TemplateType.EXCEL,
    url: '/assets/Krasnodar/1.html',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Дом, квартира',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Хабаровск 3',
    type: TemplateType.EXCEL,
    url: '/assets/Habarovsk3/1.html',
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Дом, квартира',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      }
    ]
  },
  {
    name: 'Тинькофф 4 русский',
    url: '/assets/tinkoff4ru/1.html',
    casesUrl: '/assets/tinkoff4ru/cases.json',
    type: TemplateType.WORD,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Дом, квартира, улица',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'city',
        title: 'Город',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'state',
        title: 'Область',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'index',
        title: 'Индекс',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'account-number',
        title: 'Номер счета',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'cardNumber',
        title: 'Номер карты',
        type: FieldType.SHORT_TEXT,
        formatFunction: (val: string) => {
          let mask='';
          let digitsCount = val.length < 16 ? 9 : 10;
          for (let i =0;i<Math.min(19,val.length)-digitsCount;i++) {
            mask += '*';
          }
          const endingDigits = val.length < 16 ? 3 : 4;
          const secondPart = mask + val.substr(Math.min(19,Math.max(val.length,13))-endingDigits, endingDigits)
          return val.substring(0,6) + (val.length < 13 ? '' : secondPart);
        },
      },
      {
        name: 'receiver-acc',
        title: 'Счет получателя',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'receiver',
        title: 'Получатель',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      },
    ]
  },
  {
    name: 'Тинькофф 4 англ',
    url: '/assets/tinkoff4en/1.html',
    casesUrl: '/assets/tinkoff4en/cases.json',
    type: TemplateType.WORD,
    fields: [
      {
        name: 'fio',
        title: 'ФИО',
        type: FieldType.SHORT_TEXT,
      },
      {
        name: 'address',
        title: 'Дом, квартира, улица',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'city',
        title: 'Город',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'index',
        title: 'Индекс',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'client-account',
        title: 'Номер счета',
        type: FieldType.SHORT_TEXT
      },
      {
        name: 'overallDate',
        title: 'Дата',
        type: FieldType.DATE
      },
    ]
  },
];

@Injectable({
  providedIn: 'root'
})
export class HtmlService {


  private currentTemplate: WordTemplate;
  private currentDocument: HTMLDivElement;
  private randomNumericTags: NumericTemplate[];
  private randomCurrencyTags: CurrencyTemplate[];
  private dateTags: DateTemplate[];
  private monthTags: MonthTemplate[];
  private randomAccountTags: AccountTemplate[];
  private randomDateRangeTags: DateRangeTemplate[];
  private randomTimeTags: TimeTemplate[];
  private randomCaseTags: CasesTemplate[];
  private randomStampTags: StampTemplate[];
  private monthes: string[];
  private monthNames: string[];
  private monthesEn: string[];
  private billDataFields: string[];
  private xPositionAttributes: string[];
  private yPositionAttributes: string[];
  private overallDate: Date;
  private randomTagsMap: Map<HTMLDivElement, NumericTemplate>;
  private selectedTemplateIdSubj: BehaviorSubject<number> = new BehaviorSubject(0);
  public selectedTemplateId$: Observable<number> = this.selectedTemplateIdSubj.asObservable();
  public selectedTemplate$: Observable<WordTemplate> = this.selectedTemplateId$.pipe(map((id) => templates[id]));
  private currentDocumentSubj: BehaviorSubject<HTMLDivElement> = new BehaviorSubject(null);
  public currentDocument$: Observable<HTMLDivElement> = this.currentDocumentSubj.asObservable();
  private cases: string[][];
  private qrCodeServce : QRCodeService;
  private barcodeService: BarcodeService;

  constructor(private http: HttpClient) {}

  public async selectTemplate(template: WordTemplate): Promise<string> {
    const templateId = templates.indexOf(template);
    this.selectedTemplateIdSubj.next(templateId);
    this.currentTemplate = template;
    this.monthes = [' Января ', ' Февраля ', ' Марта ', ' Апреля ', ' Мая ', ' Июня ',
      ' Июля ', ' Августа ', ' Сентября ', ' Октября ', ' Ноября ', ' Декабря '
    ];
    this.monthNames = [' Январь ', ' Февраль ', ' Март ', ' Апрель ', ' Май ', ' Июнь ',
      ' Июль ', ' Август ', ' Сентябрь ', ' Октябрь ', ' Ноябрь ', ' Декабрь '
    ];
    this.monthesEn = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    this.billDataFields = ['Адрес', 'УК-Адрес', 'Город', 'Улица', 'Дом', 'Название-сокращённое', 'Название-полное', 'Диспетчерская служба',
      'Телефон', 'ИНН', 'ОГРН', 'E-mail', 'Банк', 'БИК', 'р/с', 'new', 'к/с', 'КПП', 'Веб-сайт'];
    this.xPositionAttributes = ['margin-left', 'left', 'margin-right', 'right'];
    this.yPositionAttributes = ['margin-top', 'top', 'margin-bottom', 'bottom'];
    this.qrCodeServce = new QRCodeService(this.http);
    this.barcodeService = new BarcodeService();
    const templateUrl = templates[templateId].url;
    if (!templateUrl) {
      this.currentDocumentSubj.next(null);
      return;
    }
    try {
      const content = await this.http.get(templateUrl, {responseType: 'text'})
        .pipe(take(1)).toPromise();
      const htmlObject = document.createElement('div');
      htmlObject.innerHTML = content;
      this.currentDocument = htmlObject;
      this.currentDocumentSubj.next(htmlObject);
      if (this.currentTemplate.casesUrl) {
        const casesContent = await this.http.get(this.currentTemplate.casesUrl, {responseType: 'text'})
        .pipe(take(1)).toPromise();
        this.cases = JSON.parse(casesContent);
      }
      const styleElem : HTMLLinkElement = htmlObject.querySelector('link[rel="stylesheet"]');
      if (styleElem) {
        const templateService = this;
        styleElem.onload = function() {
          templateService.start.call(templateService);
        } 
      } else {
        this.start();
      }
      return content;
    } catch (e) {
      return Promise.reject();
    }
    // const file: File = File.createFromFileName()
    /*const reader: FileReader = new FileReader();
    const binaryXlsx: string = await reader.readAsBinaryString(path);
    return XLSX.read(binaryXlsx, {type: 'binary'});*/
  }

  private start(): void {
    this.deleteRandomlyDeletedTags();
    this.initRandomTags();
    this.overallDate = new Date();
    this.overallDate.setDate(this.overallDate.getDate() + (-3 + Math.floor(Math.random()*4)));
    this.randomizeTags();
  }

  private setQR() {
    const canvas : HTMLCanvasElement = this.currentDocument.querySelector('canvas#qr-canvas');
    const qrString = new String('ST00011\\Name=$service$\\PersonalAcc=$serviceAccount$\\Bank'
      +'Name=$serviceBankName$\\BIC=$serviceBIC$\\Corresp'
      +'Acc=$serviceCA$\\Sum=$sum$\\Pay eelNN=$serviceINN$\\Name=$fio$\\payerAddress=$address$\\pers'
      +'Acc=$account$\\paymPeriod=$period$\\serviceName=$serviceNumber$\\counterVal=$counterValue$\\category=|')
    .replace(/\$[^\$]+\$/g, match => {
      const linkedTag = this.currentDocument.querySelector(`[qrData='${match.substr(1,match.length-2)}']`) as HTMLElement;
      if (linkedTag === null) {
        return '0';
      }
      return linkedTag.innerText;
    });
    console.log('QR data: ' + qrString);
    this.qrCodeServce.renderCodeOnCanvas(qrString, canvas);
  }

  private setBarcode() {
    const canvas : HTMLCanvasElement = this.currentDocument.querySelector('canvas#barcode-canvas');
    const barcodeDataTag : HTMLElement = this.currentDocument.querySelector('[barcode-data]') ;
    const barcodeString = barcodeDataTag ? 
      this.getStringFromTemplate(this.getTemplateFromString(barcodeDataTag.innerText))
      : this.getStringFromTemplate(this.getTemplateFromString('0123456789'));
    this.barcodeService.setBarcode(canvas, barcodeString);
  }

  public setBillData(billData: string) {
    const htmlService = this;
    this.billDataFields.forEach(f => {
      const regExp = new RegExp('^' + f + `:\\s*(.*)$`, 'gm');
      const searchResult = regExp.exec(billData);
      if (searchResult !== null && searchResult.length) {
        console.log(searchResult[1]);
        const tags  = htmlService.currentDocument.querySelectorAll(`[billdata='${f}']`);
        if (tags.length) {
          tags.forEach(tag => tag.innerHTML = searchResult[1]);
        }
      }
    });
  }


  public get templates(): WordTemplate[] {
    return templates;
  }
  public getCurrentTemplate(): WordTemplate {
    return this.currentTemplate;
  }

  public getCurrentDocument(): HTMLDivElement {
    return this.currentDocument;
  }

  public updateTemplateValues(formData: {[key: string]: string}): void {
    if (formData['overallDate']) {
      this.updateTagValue('overallDate', formData['overallDate']);
    }
    Object.keys(formData).filter(k => k != 'overallDate').forEach((key) => {
      if (formData[key] !== null) {
        this.updateTagValue(key, formData[key]);
      }
    });
  }

  public updateTagValue(name: string, value: any): void {
    const field = this.currentTemplate.fields.find(f => f.name === name);
    this.currentDocument.querySelectorAll(`[name="${name}"]`).forEach((elem) => {
      if (field.formatFunction) {
        (elem as HTMLDivElement).innerText = field.formatFunction.call(this, value);
      } 
       else {
        if (field.type === FieldType.DATE) {
          (elem as HTMLDivElement).innerText = this.dateFormatFunction(new Date(value), 'number');
        }
        else {
          (elem as HTMLDivElement).innerText = value.toString();
        }
      }
    });
    if (name === 'overallDate') {
      this.overallDate = new Date(value);
      this.randomizeTags();
    }
  }

  public saveWorkbook(): void {
  }

  private deleteRandomlyDeletedTags() {
    const randomlyDeletedTags : Array<Node> = Array.prototype.slice.call(this.currentDocument.querySelectorAll('[deletionPossible]'));
    const maxTagsToDelete = this.currentTemplate.maxElementsDeteled ? this.currentTemplate.maxElementsDeteled : randomlyDeletedTags.length; 
    const numberOfTagsToDelete = Math.floor(Math.random() * (maxTagsToDelete+0.99));
    for (let i = 0; i<numberOfTagsToDelete; i++) {
      var deletedTagIndex = Math.floor(Math.random() * (randomlyDeletedTags.length-0.001));
      randomlyDeletedTags[deletedTagIndex].parentNode.removeChild(randomlyDeletedTags[deletedTagIndex]);
      randomlyDeletedTags.splice(deletedTagIndex, 1);
    }
  }

  private initRandomTags(): void {
    this.randomNumericTags = [];
    this.randomCurrencyTags = [];
    this.randomAccountTags = [];
    this.randomDateRangeTags = [];
    this.randomTimeTags = [];
    this.dateTags = [];
    this.monthTags = [];
    this.randomCaseTags = [];
    this.randomStampTags = [];
    this.randomTagsMap = new Map();
    let tag : HTMLElement;
    console.log('start');
    this.currentDocument.querySelectorAll('[randomize]').forEach((elem: Element) => {
      tag = (elem as HTMLElement);
      try {
        switch (tag.getAttribute('randomize')) {
          case 'number': this.randomNumericTags.push(this.initNumericRandomTag(tag));
          break;
          case 'currencyEn': this.randomCurrencyTags.push(this.initCurrencyRandomTag(tag, 'en'));
          break;
          case 'currencyRu': this.randomCurrencyTags.push(this.initCurrencyRandomTag(tag, 'ru'));
          break;
          case 'date': this.dateTags.push(this.initDateTag(tag));
          break;
          case 'account': this.randomAccountTags.push(this.initAccountTag(tag));
          break;
          case 'dateRange': this.randomDateRangeTags.push(this.initDateRangeTag(tag));
          break;
          case 'time': this.randomTimeTags.push(this.initRandomTimeTag(tag));
          break;
          case 'cases': this.randomCaseTags.push(this.initCasesTag(tag));
          break;
          case 'month': this.monthTags.push(this.initMonthTag(tag));
          break;
          case 'stamp': this.randomStampTags.push(this.initStampTag(tag));
          break;
        }
      }
      catch(e) {
        console.log(tag);
        console.log(e);
      }
    });
  }

  private initRules(tag: HTMLElement) : RandomizeRule[] {
    let ruleType: RuleType;
    if (ruleType = tag.getAttribute('rule') as RuleType) {
      const rule : RandomizeRule = {
        type: ruleType,
        linkedTagsIds: tag.getAttribute('ruleReference').split(';')
      }
      return [rule];
    }
    return [];
  }

  private dateFormatFunction(val : Date, monthFormat: MonthFormat, yearFormat?: string, dateFormat?: string) : string {
    const day = dateFormat === 'none' ? '' : val.getDate() > 9 ? val.getDate() : '0'.concat(val.getDate().toString());
    let month:string;
    switch(monthFormat) {
      case 'text': month = this.monthes[val.getMonth()];
      break;
      case 'name': month = this.monthNames[val.getMonth()];
      break;
      case 'nameEn': month = ` ${this.monthesEn[val.getMonth()]} `;
      break;
      case 'short': month = ' ' + this.monthes[val.getMonth()].substr(1,3) + ' ';
      break;
      case 'togetherEn': month = this.monthesEn[val.getMonth()];
      break;
      case 'togetherEnShort': month = this.monthesEn[val.getMonth()].substr(0,3);
      break;
      case 'slashes': month = `/${val.getMonth()+1 > 9 ? val.getMonth()+1 : '0'.concat((val.getMonth()+1).toString())}/`;
      break;
      case 'none': month = '';
      break;
      case 'spaces': month = ` ${val.getMonth()+1 > 9 ? val.getMonth()+1 : '0'.concat((val.getMonth()+1).toString())} `;
      break;
      case 'together': month = (val.getMonth()+1 > 9 ? val.getMonth()+1 : '0'.concat((val.getMonth()+1).toString())).toString();
      break;
      default: month = `.${val.getMonth()+1 > 9 ? val.getMonth()+1 : '0'.concat((val.getMonth()+1).toString())}.`;
    }
    let year : string | number;
    switch(yearFormat) {
      case 'short': year = val.getFullYear() % 2000;
      break;
      case 'none': year = '';
      break;
      default: year = val.getFullYear();
    }
    return `${day}${month}${year}`;
  }

  private timeFormatFunction(val : Date) : string {
    return val.toUTCString().match(/[0-2][0-9]:[0-5][0-9]/)[0];
  }

  private floorTo(val : number, fractionalDigits : number) : number {
    return Math.floor(val * Math.pow(10, Math.floor(fractionalDigits))) / Math.pow(10, Math.floor(fractionalDigits));
  }

  private parseDate(text: string) : Date {
    const dayMonthYearCurrent = text.slice().split('.');
    return new Date(parseInt(dayMonthYearCurrent[2]), parseInt(dayMonthYearCurrent[1]), parseInt(dayMonthYearCurrent[0]));
  }

  private initStampTag(tag: HTMLElement) : StampTemplate {
    const style = window.getComputedStyle(tag);
    const xPositionAttribute = tag.getAttribute('xPosAttr') 
    || this.xPositionAttributes.find(a => style[a] && style[a] !== 'auto') || 'margin-left';
    const xPositionAttributeValue = style[xPositionAttribute].match(/-?[0-9]+\.?[0-9]*/);
    const initialX = xPositionAttributeValue.length ? parseFloat(xPositionAttributeValue[0]) : 0;
    const yPositionAttribute = tag.getAttribute('yPosAttr') 
    || this.yPositionAttributes.find(a => style[a] && style[a] !== 'auto') || 'margin-top';
    const yPositionAttributeValue = style[yPositionAttribute].match(/-?[0-9]+\.?[0-9]*/);
    const initialY = yPositionAttributeValue.length ? parseFloat(yPositionAttributeValue[0]) : 0;

    const stampTemplate: StampTemplate = {
      xRange: parseFloat(tag.getAttribute('xRange')) || 20,
      yRange: parseFloat(tag.getAttribute('yRange')) || 20,
      initialX,
      initialY,
      angleRange: parseFloat(tag.getAttribute('angleRange')) || 45,
      elem : tag,
      xPositionAttribute,
      yPositionAttribute
    };
    return stampTemplate;
  }

  private initDateRangeTag(tag: HTMLElement) : DateRangeTemplate {
    const curVal = this.parseDate(tag.innerText);
    const dateRange: DateRangeTemplate = {
      value: curVal,
      template: (val: Date) => this.dateFormatFunction(val,
        tag.getAttribute('monthFormat') as MonthFormat,
        tag.getAttribute('yearFormat'), 
        tag.getAttribute('dateFormat')),
      elem: tag,
      minDifference: tag.hasAttribute('minDifference') ? parseInt(tag.getAttribute('minDifference')) : 0,
      maxDifference: tag.hasAttribute('maxDifference') ? parseInt(tag.getAttribute('maxDifference')) : 0,
      id: tag.getAttribute('randomizeId'),
      rules: this.initRules(tag)
    }
    return dateRange;
  }

  private initAccountTag(tag: HTMLElement): AccountTemplate {
    return {
      value: tag.hasAttribute('checking') ? tag.innerText : this.getTemplateFromString(tag.innerText),
      template: (val : string) => val,
      elem: tag,
      id: tag.getAttribute('randomizeId'),
      rules: this.initRules(tag)
    }
  }

  private getTemplateFromString(input: string) :string {
    input = input.replace(/[a-z]/g, 's');
    input = input.replace(/[A-Z]/g, 'S');
    input = input.replace(/[0-9]/g, 'd');
    return input;
  }

  private getStringFromTemplate(template: string): string {
    const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const digits = '0123456789';
    let newVal = '';
    for (let i =0; i< template.length; i++ ){
      switch(template.charAt(i)){
        case 's':newVal = newVal.concat(lowerCaseChars.charAt(Math.floor(Math.random() * lowerCaseChars.length)));
        break;
        case 'S':newVal = newVal.concat(upperCaseChars.charAt(Math.floor(Math.random() * upperCaseChars.length)));
        break;
        case 'd':newVal = newVal.concat(digits.charAt(Math.floor(Math.random() * digits.length)));
        break;
        default: newVal = newVal.concat(template.charAt(i))
      }
    }
    return newVal;
  }

  private initDateTag(tag: HTMLElement) : DateTemplate {
    const curVal = this.parseDate(tag.innerText);
    const diff = tag.hasAttribute('difference') ? parseInt(tag.getAttribute('difference')) : 0;
    const date: DateTemplate = {
      value: curVal,
      template: (val: Date) => this.dateFormatFunction(
        val, 
        tag.getAttribute('monthFormat') as MonthFormat,
        tag.getAttribute('yearFormat'), 
        tag.getAttribute('dateFormat')),
      elem: tag,
      difference: diff,
      id: tag.getAttribute('randomizeId'),
      rules: this.initRules(tag)
    }
    return date;
  }

  private initMonthTag(tag: HTMLElement) : MonthTemplate {
    const curVal = this.parseDate(tag.innerText);
    const diff = tag.hasAttribute('difference') ? parseInt(tag.getAttribute('difference')) : 0;
    const date: MonthTemplate = {
      value: curVal,
      template: (val: Date) => this.monthNames[val.getMonth()] + val.getFullYear(),
      elem: tag,
      difference: diff,
      id: tag.getAttribute('randomizeId'),
      rules: this.initRules(tag)
    }
    return date;
  }

  private initRandomTimeTag(tag: HTMLElement) : TimeTemplate {
    const curVal = new Date('January 1, 1970 ' + tag.innerText);
    const date: TimeTemplate = {
      value: curVal,
      template: this.timeFormatFunction,
      elem: tag,
      id: tag.getAttribute('randomizeId'),
      rules: this.initRules(tag),
      timeRange: parseInt(tag.getAttribute('timeRange'))
    }
    return date;
  }

  private initCurrencyRandomTag(tag : HTMLElement, language: string) : CurrencyTemplate {
    const curVal: number = parseFloat((tag.innerText.slice()
      .match(/(((\d)+?([.,]\d+)?)([ ]?))+/) || ['100'])[0].replace(' ', '').replace(',','.')
    );
    const formatFunction = (val : number) => {
      if (tag.hasAttribute('rublesOnly')) {
        let _string : string = val.toString().substring(0, val.toString().indexOf('.')+1).replace('.', ',') + '00';
        let currencyInText : string = number_to_stringRu(val);
        let rublesWordIndex = currencyInText.indexOf('рубл');
        _string += ' (' + currencyInText.substring(0, rublesWordIndex)+ 'и 0/100) ';
        let rublesWordEndIndex = currencyInText.indexOf(' ', rublesWordIndex);
        _string += currencyInText.substring(rublesWordIndex, rublesWordEndIndex) + ' РФ.';
        return _string;
      } else if (tag.hasAttribute('rublesTextOnly')) {
        if (language === 'ru') {
          let currencyInText : string = number_to_stringRu(val);
          let rublesWordIndex = currencyInText.indexOf('рубл');
          let rublesWordEndIndex = currencyInText.indexOf(' ', rublesWordIndex);
          let _string = currencyInText.substring(0, rublesWordEndIndex);
          return _string[0].toUpperCase() + _string.substr(1);
        } else {
          let currencyInText : string = number_to_stringEn(val);
          let rublesWordIndex = currencyInText.indexOf('rubl');
          return currencyInText.substring(0, rublesWordIndex);
        }
      } else if (tag.hasAttribute('shortCurrencyNames')){
        let _string =  `${this.floorTo(val, 0)} р. ${new Number(val%1*100).toFixed()} `;
        if (tag.hasAttribute('shorterCents')) {
          return _string + 'к.';
        }
        return _string+'коп.'
      } else {
        let _string : string = language === 'en' ? val.toString() : val.toString().replace('.', ',');
        _string = _string + (language === 'en' ? ' rubles' : ' рублей') + ' (';
        let currencyInText : string = language === 'en' ? number_to_stringEn(val) : number_to_stringRu(val);
        currencyInText = currencyInText[0].toUpperCase() + currencyInText.substring(1);
        return _string + currencyInText + ')';
      }
    }
    const currency: CurrencyTemplate = {
      value: curVal,
      template: formatFunction,
      elem: tag,
      id: tag.getAttribute('randomizeId'),
      rules: this.initRules(tag),
      expression: tag.getAttribute('expression')
    };
    if (tag.hasAttribute('minValue')) {
      currency.minValue = parseFloat(tag.getAttribute('minValue'));
    }
    if (tag.hasAttribute('maxValue')) {
      currency.maxValue = parseFloat(tag.getAttribute('maxValue'));
    }
    if (tag.hasAttribute('randomizeId')) {
      currency.id = tag.getAttribute('randomizeId');
    }
    return currency;
  }

  private initNumericRandomTag(tag : HTMLElement) : NumericTemplate {
    const curVal: number = parseFloat(tag.innerText.slice()
      .match(/(([-]?(\d)+?([.,]\d+)?)([ ]?))+/)[0].replace(' ', '').replace(',','.')
    );
    const numeric: NumericTemplate = {
      value: curVal,
      template: (val: number) => {
        const fractionalDigits = tag.hasAttribute('fractionalDigits') ? parseInt(tag.getAttribute('fractionalDigits')) : 2;
        let str = val.toString();
        str += str.indexOf('.') === -1 ? '.000000' : '000000';
        const numberEnd = fractionalDigits === 0 ? str.indexOf('.') - 1 : str.indexOf('.') + fractionalDigits;
        str = str.substring(0,numberEnd+1);
        if (tag.hasAttribute('groupSep')) {
          const groupSep = tag.getAttribute('groupSep') || ' ';
          const groupSepMarginFromDot = parseInt(tag.getAttribute('groupSepPosition')) || 3;
          const groupSepPos = fractionalDigits === 0 ? str.length - groupSepMarginFromDot : str.indexOf('.') - groupSepMarginFromDot;
          if (groupSepPos > 0) {
            str = str.substring(0, groupSepPos) + groupSep + str.substring(groupSepPos);
          }
        }
        if (tag.hasAttribute('trim')) {
          str = str.replace(/[0]+$/, '').replace(/[\.]+$/, '');
        }
        if (tag.hasAttribute('comma')) {
          str = str.replace('.', ',');
        }
        return str;
      },
      elem: tag,
      rules: this.initRules(tag),
      handled: false,
      expression: tag.getAttribute('expression')
    };
    if (tag.hasAttribute('randomizeId')) {
      numeric.id = tag.getAttribute('randomizeId');
    }
    if (tag.hasAttribute('minValue')) {
      numeric.minValue = parseFloat(tag.getAttribute('minValue'));
    }
    if (tag.hasAttribute('maxValue')) {
      numeric.maxValue = parseFloat(tag.getAttribute('maxValue'));
    }
    return numeric;
  }

  private initCasesTag(tag: HTMLElement): CasesTemplate {
    const casesId = tag.getAttribute('casesId');
    return {
      value: tag.innerText,
      template: (val : string) => val,
      elem: tag,
      id: tag.getAttribute('randomizeId'),
      rules: this.initRules(tag),
      cases: this.cases[parseInt(casesId)]
    }
  }

  private randomizeNumericTag(numeric: NumericTemplate, index: number) {
    if (this.randomNumericTags[index].handled) {
      return;
    }
    const tag: HTMLElement = numeric.elem;
    try {
      let newVal: number;
      const spreadPercent = numeric.spreadPercent ? numeric.spreadPercent : 0.5;
      if (numeric.rules && numeric.rules.length > 0) {
        const linkedTags = this.randomNumericTags.filter(c => numeric.rules.some(r => r.linkedTagsIds.includes(c.id)));
        linkedTags.forEach(t => this.randomizeNumericTag.call(this, t, this.randomNumericTags.indexOf(t)));
        numeric.rules.forEach(rule => {
          switch (rule.type) {
            case '=': {
              newVal =  linkedTags.find(t => t.id === rule.linkedTagsIds[0]).value;
            }
            break;
            case '+': {
              newVal = linkedTags
              .filter(c => rule.linkedTagsIds.includes(c.id)).reduce((curSum, elem) => curSum + elem.value, 0);
            }
            break;
            case '<=': { 
              const maxVal = linkedTags.find(t => t.id === rule.linkedTagsIds[0]).value;
              newVal = maxVal - maxVal * spreadPercent * Math.random();
            }
            break;
            case '>=': {
              const minVal = linkedTags.find(t => t.id === rule.linkedTagsIds[0]).value;
              newVal = minVal + minVal * spreadPercent * Math.random();
            }
          }
        });
      } else if(numeric.expression){
        const fullExpression = numeric.expression.replace(/\$[^\$]+\$/g, match => {
          const linkedTagIndex = this.randomNumericTags.findIndex(t => t.id === match.substr(1,match.length-2));
          if (linkedTagIndex === -1) {
            return '0';
          }
          this.randomizeNumericTag.call(this, this.randomNumericTags[linkedTagIndex], linkedTagIndex);
          return this.randomNumericTags[linkedTagIndex].value.toString();
        });
        console.log(fullExpression, eval(fullExpression));
        newVal = eval(fullExpression);
        console.log(newVal);
      } else {
        if (numeric.minValue && numeric.maxValue) {
          newVal = Math.random() * (numeric.maxValue - numeric.minValue) + numeric.minValue;
        } else if (numeric.minValue) {
          newVal = numeric.minValue + numeric.minValue * spreadPercent * Math.random();
        } else if (numeric.maxValue) {
          newVal = numeric.maxValue - numeric.maxValue * spreadPercent * Math.random();
        } else {
          newVal = (Math.random() * spreadPercent - spreadPercent/2)*numeric.value + numeric.value;
        }
      }
      const newValFormatted = numeric.template ? numeric.template(newVal) : newVal.toString();
      tag.innerText = newValFormatted;
      this.randomNumericTags[index].handled = true;
      this.randomNumericTags[index].value =
        this.floorTo(newVal, 
          tag.hasAttribute('fractionalDigits') ? parseInt(tag.getAttribute('fractionalDigits')) : 2
        );
    } catch (e) {
      console.log(e);
    }
  }

  private randomizeCurrencyTag(currency: CurrencyTemplate, index: number) {
    if (this.randomCurrencyTags[index].handled) {
      return;
    }
    const tag: HTMLElement = currency.elem;
    try {
      let newVal: number;
      if (currency.rules && currency.rules.length > 0) {
        const linkedCurrencyTags = this.randomCurrencyTags.filter(c => currency.rules.some(r => r.linkedTagsIds.includes(c.id)));
        linkedCurrencyTags.forEach(this.randomizeCurrencyTag, this);
        const linkedNumericTags = this.randomNumericTags.filter(c => currency.rules.some(r => r.linkedTagsIds.includes(c.id)));
        linkedNumericTags.forEach(this.randomizeNumericTag, this);
        currency.rules.forEach(rule => {
          switch (rule.type) {
            case '=': newVal = 
              (linkedCurrencyTags.find(c => c.id === rule.linkedTagsIds[0]) || linkedNumericTags.find(c => c.id === rule.linkedTagsIds[0]))
                .value;
            break;
            case '+': newVal = linkedCurrencyTags
              .filter(c => rule.linkedTagsIds.includes(c.id))
              .reduce((curSum, elem) => curSum + elem.value, 0);
            break;
          }
        });
      } else if(currency.expression){
        const fullExpression = currency.expression.replace(/\$[^\$]+\$/g, match => {
          let linkedTagIndex = this.randomNumericTags.findIndex(t => t.id === match.substr(1,match.length-2));
          if (linkedTagIndex === -1) {
            linkedTagIndex = this.randomCurrencyTags.findIndex(t => t.id === match.substr(1,match.length-2));
            if (linkedTagIndex === -1) {
              return '0';
            }
            this.randomizeCurrencyTag.call(this, this.randomCurrencyTags[linkedTagIndex], linkedTagIndex);
            return this.randomCurrencyTags[linkedTagIndex].value.toString();
          }
          this.randomizeNumericTag.call(this, this.randomNumericTags[linkedTagIndex], linkedTagIndex);
          return this.randomNumericTags[linkedTagIndex].value.toString();
        });
        console.log(fullExpression);
        newVal = eval(fullExpression);
        console.log(newVal);
      } else {
        const spreadPercent = currency.spreadPercent ? currency.spreadPercent : 0.5;
        if (currency.minValue && currency.maxValue) {
          newVal = Math.random() * (currency.maxValue - currency.minValue) + currency.minValue;
        } else if (currency.minValue) {
          newVal = currency.minValue + currency.minValue * spreadPercent * Math.random();
        } else if (currency.maxValue) {
          newVal = currency.maxValue - currency.maxValue * spreadPercent * Math.random();
        } else {
          newVal = (Math.random() * spreadPercent - spreadPercent/2)*currency.value + currency.value;
        }
      }
      newVal = Math.round(newVal*100)/100;
      const newValFormatted = currency.template ? currency.template(newVal) : newVal.toString();
      tag.innerText = newValFormatted;
      this.randomCurrencyTags[index].handled = true;
      this.randomCurrencyTags[index].value = newVal;
    } catch (e) {
      console.log(e);
    }
  }

  private randomizeDateTag(date : DateTemplate, index : number) {
    if (this.dateTags[index].handled) {
      return;
    }
    const tag: HTMLElement = date.elem;
    try {
      let newVal : Date;
      if (date.rules && date.rules.length > 0) {
        const linkedDateTags = this.dateTags.filter(c => date.rules.some(r => r.linkedTagsIds.includes(c.id)));
        const linkedDateRangeTags = this.randomDateRangeTags.filter(c => date.rules.some(r => r.linkedTagsIds.includes(c.id)));
        linkedDateTags.forEach(t => this.randomizeDateTag.call(this, t, this.dateTags.indexOf(t)));
        linkedDateRangeTags.forEach(t => this.randomizeDateRangeTag.call(this, t, this.randomDateRangeTags.indexOf(t)));
        date.rules.forEach(rule => {
          switch (rule.type) {
            case '=': {
              let d =  linkedDateTags.find(c => c.id === rule.linkedTagsIds[0]);
              if (d) {
                newVal = d.value
              } else {
                newVal = linkedDateRangeTags.find(c => c.id === rule.linkedTagsIds[0]).value;
              }
            }
            break;
          }
        });
      } else {
        newVal = new Date(this.overallDate.valueOf());
        newVal.setDate(newVal.getDate() + date.difference);
      }
      const newValFormatted = date.template(newVal);
      tag.innerText = newValFormatted;
      this.dateTags[index].value = newVal;
      this.dateTags[index].handled = true;
    } catch (e) {
      console.log(e);
    }
  }

  private randomizeMonthTag(date : MonthTemplate, index : number) {
    const tag: HTMLElement = date.elem;
    try {
      const newVal : Date = new Date(this.overallDate.valueOf());
      newVal.setDate(newVal.getDate() + date.difference);
      const newValFormatted = date.template(newVal);
      tag.innerText = newValFormatted;
    } catch (e) {
      console.log(e);
    }
  }

  private randomizeTimeTag(time : TimeTemplate, index : number) {
    const tag: HTMLElement = time.elem;
    try {
      const rangeSize = 3600 * 1000 * 8;
      let millseconds = rangeSize * Math.random();
      millseconds = millseconds + rangeSize * (time.timeRange - 1); 
      time.value.setTime(millseconds);
      const newValFormatted = time.template(time.value);
      tag.innerText = newValFormatted;
    } catch (e) {
      console.log(e);
    }
  }

  private randomizeAccountTag(account: AccountTemplate, index: number) {
    if (this.randomAccountTags[index].handled) {
      return;
    }
    const tag: HTMLElement = account.elem;
    try {
      let newVal: string;
      if (account.rules && account.rules.length > 0) {
        const linkedTags = this.randomAccountTags.filter(c => account.rules.some(r => r.linkedTagsIds.includes(c.id)));
        linkedTags.forEach(t => this.randomizeAccountTag.call(this, t, this.randomAccountTags.indexOf(t)));
        account.rules.forEach(rule => {
          switch (rule.type) {
            case '=': newVal = linkedTags.find(c => c.id === rule.linkedTagsIds[0]).elem.innerText;
            break;
          }
        });
      } else {
        if (tag.hasAttribute('checking')) {
          const newInternalNumber = this.getStringFromTemplate(this.getTemplateFromString(account.value.substr(13)));
          const bicTag = this.currentDocument.querySelector('[BIC]');
          if (bicTag && bicTag.innerHTML.length > 2) {
            newVal = this.calculateControlDigit(bicTag.innerHTML, account.value.substr(0,13) + newInternalNumber)
          } else {
            newVal = account.value.substr(0,13) + newInternalNumber;
          }
        }
        else {
          newVal = this.getStringFromTemplate(account.value);
        }
      }
      newVal = account.template(newVal);
      tag.innerText = newVal;
      this.randomAccountTags[index].handled = true;
    } catch (e) {
      console.log(e);
    }
  }
  private calculateControlDigit(bic: string, checkingAccount: string): string {
    const weightCoefficents = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
    let sum = 0;
    const bicWithAccount = bic.substr(bic.length - 3, 3) + checkingAccount;
    for (let i = 0; i < 23; i++) {
      if (i === 8)
        continue;
      sum += parseInt(bicWithAccount[i]) * weightCoefficents[i];
    }
    const sumStr = sum.toString();
    return checkingAccount.substr(0,8) + sumStr[sumStr.length - 1] + checkingAccount.substr(9);
  }

  private randomizeDateRangeTag(date: DateRangeTemplate, index: number) {
    if (this.randomDateRangeTags[index].handled) {
      return;
    }
    const tag: HTMLElement = date.elem;
    try {
      const newVal : Date = new Date(this.overallDate.valueOf());
      const difference = Math.round(Math.random() * (date.maxDifference - date.minDifference) + date.minDifference);
      newVal.setDate(newVal.getDate() + difference);
      const newValFormatted = date.template(newVal);
      tag.innerText = newValFormatted;
      this.randomDateRangeTags[index].value = newVal;
      this.randomDateRangeTags[index].handled = true;
    } catch (e) {
      console.log(e);
    }
  }

  private randomizeCasesTag(casesTemplate: CasesTemplate, index:number) {
    if (this.randomCaseTags[index].handled) {
      return;
    }
    try {
      let newVal: string;
      if (casesTemplate.rules && casesTemplate.rules.length > 0) {
        const linkedTags = this.randomCaseTags.filter(c => casesTemplate.rules.some(r => r.linkedTagsIds.includes(c.id)));
        linkedTags.forEach(this.randomizeCasesTag, this);
        casesTemplate.rules.forEach(rule => {
          switch (rule.type) {
            case '=': newVal = linkedTags.find(c => c.id === rule.linkedTagsIds[0]).elem.innerHTML;
            break;
          }
        });
      } else {
        newVal = this.getRandomArrayElem(casesTemplate.cases);
      }
      casesTemplate.elem.innerHTML = newVal;
      this.randomCaseTags[index].handled = true;
    } catch (e) {
      console.log(e);
    }
  }

  private randomizeStampTag(stampTemplate: StampTemplate, index: number) {
    const xOffset = Math.random() * stampTemplate.xRange * 2 - stampTemplate.xRange;
    stampTemplate.elem.style[stampTemplate.xPositionAttribute] = (stampTemplate.initialX + xOffset).toString()+'px';
    const yOffset = Math.random() * stampTemplate.yRange * 2 - stampTemplate.yRange;
    stampTemplate.elem.style[stampTemplate.yPositionAttribute] = (stampTemplate.initialY + yOffset).toString()+'px';
    const rotation = Math.random() * stampTemplate.angleRange * 2 - stampTemplate.angleRange;
    stampTemplate.elem.style.transform = `rotate(${rotation}deg)`;
  }

  private getRandomArrayElem(array: any[]) {
    if (array.length > 0) {
      return array[Math.floor((array.length-0.001)*Math.random())]
    } else {
      return undefined;
    }
  }

  public randomizeTags(): void {
    if (!this.randomNumericTags) debugger;
    this.randomNumericTags = this.randomNumericTags.map(t => {t.handled = false; return t;});
    this.randomAccountTags = this.randomAccountTags.map(t => {t.handled = false; return t;});
    this.randomCurrencyTags = this.randomCurrencyTags.map(t => {t.handled = false; return t;});
    this.dateTags = this.dateTags.map(t => {t.handled = false; return t;});
    this.monthTags = this.monthTags.map(t => {t.handled = false; return t;});
    this.randomDateRangeTags = this.randomDateRangeTags.map(t => {t.handled = false; return t;});
    this.randomTimeTags  = this.randomTimeTags.map(t => {t.handled = false; return t;});
    this.randomCaseTags = this.randomCaseTags.map(t => {t.handled = false; return t;});
    this.randomStampTags = this.randomStampTags.map(t => {t.handled = false; return t;});

    this.randomNumericTags.forEach(this.randomizeNumericTag, this);
    this.randomCurrencyTags.forEach(this.randomizeCurrencyTag, this);
    this.dateTags.forEach(this.randomizeDateTag, this);
    this.monthTags.forEach(this.randomizeMonthTag, this);
    this.randomAccountTags.forEach(this.randomizeAccountTag, this);
    this.randomDateRangeTags.forEach(this.randomizeDateRangeTag, this);
    this.randomTimeTags.forEach(this.randomizeTimeTag, this);
    this.randomCaseTags.forEach(this.randomizeCasesTag, this);
    this.randomStampTags.forEach(this.randomizeStampTag, this);
    if (this.currentTemplate.generateQR) {
      this.setQR();
    }
    if (this.currentTemplate.generateBarcode) {
      this.setBarcode();
    }
  }

  public async resetAndRandomize() : Promise<string>
  {
    const content = await this.selectTemplate(this.currentTemplate);
    return content;
  }

  private shuffleChildren(parent: HTMLElement, childClass: string) {
    let  elementsArray : Element[] = Array.prototype.slice.call(parent.getElementsByClassName(childClass));
    elementsArray.forEach(function(element : Element){
        parent.removeChild(element);
    });
    elementsArray = this.shuffleArray(elementsArray);
    elementsArray.forEach(function(element){
      parent.appendChild(element);
    });
  }
  
  private shuffleArray(array : Element[]) : Element[] {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }


  private countDecimals(val: number): number {
    return (val.toString().split('.')[1] || []).length;
  }

  private findIndexes(array: any[], predicate: (val :any) => boolean) : number[] {
    let indexes: number[];
    for (let i = 0;i<array.length;i++) {
      if (predicate(array[i])) {
        indexes.push(i);
      }
    }
    return indexes;
  }

  public print()
  {
    window.print();
  }

}
