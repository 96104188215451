import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WordTemplate} from '../../services/html.service';

@Component({
  selector: 'app-template-item',
  templateUrl: './template-item.component.html',
  styleUrls: ['./template-item.component.scss']
})
export class TemplateItemComponent implements OnInit {
  @Output() selected: EventEmitter<void> = new EventEmitter();
  @Input() template: WordTemplate;
  @Input() isSelected = false;
  public isDisabled = false;
  constructor() { }

  ngOnInit() {
    this.isDisabled = this.template.type === 'excel' && !(this.template.billData);
  }

  onSelect(): void {
    if (!this.isSelected) {
      this.isSelected = true;
      this.selected.emit();
    }
  }
}
