import {Component, OnDestroy, ViewChild} from '@angular/core';
import {HtmlService, WordTemplate, TemplateType} from './services/html.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { BilldataInputComponent } from './components/billdata-input/billdata-input.component';

declare module fs {
  export function fn(): string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  private destroyed$: Subject<void> = new Subject();
  @ViewChild('billdatainput') billDataInput : BilldataInputComponent;
  template: WordTemplate;
  formData: {[key: string]: any} = {};
  htmlData: HTMLDivElement;
  templates: WordTemplate[] = [];
  constructor(private templatesService: HtmlService) {
    this.templates = this.templatesService.templates;
    this.templatesService.currentDocument$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((doc) => {
        this.htmlData = doc;
         if (this.billDataInput) {
           this.billDataInput.clear();
         }
      });
    this.templatesService.selectedTemplate$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((template) => {
        this.template = template;
      });
    this.initTemplatesService();
  }

  async initTemplatesService() {
    await this.templatesService.selectTemplate(this.templates[0]);
  }

  async filterTemplates(templateTypeString?: string){
    const templateType = templateTypeString as TemplateType;
    if (templateType) {
      this.templates = this.templatesService.templates.filter(t => t.type === templateType);
    } else {
      this.templates = this.templatesService.templates;
    }
    await this.templatesService.selectTemplate(this.templates[0]);
  }

  onChange(formData: {[key: string]: any}): void {
    this.formData = formData;
    this.templatesService.updateTemplateValues(this.formData);
  }

  onBillDataChange(data: string) {
    this.templatesService.setBillData(data);
  }

  saveWorkbook(): void {
    console.log(this.formData);
    this.templatesService.updateTemplateValues(this.formData);
    this.templatesService.print();
  }

  randomizeValues() {
    this.templatesService.randomizeTags();
  }

  resetAndRandomize() {
    this.templatesService.resetAndRandomize();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
  increaseZoom(): void {
    const container = document.getElementById('printZone');
    container.style.zoom = (parseFloat(container.style.zoom) * 1.5).toString();
    container.style.setProperty('-moz-transform', 'scale('+container.style.zoom+')');
  }
  decreaseZoom(): void {
    const container = document.getElementById('printZone');
    container.style.zoom = (parseFloat(container.style.zoom) / 1.5).toString();
    container.style.setProperty('-moz-transform', 'scale('+container.style.zoom+')');
  }
  resetZoom(): void{
    const container = document.getElementById('printZone');
    container.style.zoom = '2';
    container.style.setProperty('-moz-transform', 'scale(2)');
  }
}
