import {AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {HtmlService} from '../../services/html.service';

@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss']
})
export class TemplatePreviewComponent implements AfterViewInit {
  @ViewChild('container') container: ElementRef<HTMLDivElement>;
  @Input() set htmlContent(html: HTMLDivElement) {
    console.log(html);
    if (this.container) {
      if (this.container.nativeElement.children.length > 0) {
        while (this.container.nativeElement.firstChild) {
          this.container.nativeElement.removeChild(this.container.nativeElement.firstChild);
        }
      }
      if (html) {
        this.container.nativeElement.appendChild(html);
      }
    }
  }

  @Input() set template(t: string) {
    if (t) {
      this._template = t;
      this.html = this.sanitizer.bypassSecurityTrustHtml(t);
    }
  };
  get template(): string {
    return this._template;
  }
  private _template: string;
  html: SafeHtml;
  constructor(private sanitizer: DomSanitizer, private htmlService: HtmlService) {
  }

  ngAfterViewInit(): void {

  }
}
