import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-billdata-input',
  templateUrl: './billdata-input.component.html',
  styleUrls: ['./billdata-input.component.css']
})
export class BilldataInputComponent implements OnInit {
  @Output() dataChange: EventEmitter<string> = new EventEmitter();
  data : FormControl;

  constructor() { 
    this.data = new FormControl();
  }

  ngOnInit() {
  }

  public onChange() {
    this.dataChange.emit(this.data.value);
  }

  public clear() {
    //this.data.setValue('');
    this.onChange();
  }
}
